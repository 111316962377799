
import { ThemeProvider } from '@emotion/react';
import LandingPage from './pages/landing/landing';
import theme from './theme';
import { RouteObject, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import OpeningPage from './pages/openings';
import DashboardPage from './pages/dashboard';
import RequestsPage from './pages/dashboard-pages/admin/requests';
import OpeningList from './shared/components/openings/opening-list';
import OpeningRequestStepper from './shared/components/openings/opening-request-stepper';
import { createClient } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import clientService from './shared/services/client-service';
import Client from './shared/models/client';
import SetupPage from './pages/dashboard-pages/client/setup';
import ClientHome from './pages/dashboard-pages/client/home';
import JobOffer from './pages/dashboard-pages/client/job-offer';
import ContactUsPage from './pages/contact-us';
import Confirmation from './shared/components/confirmation';
import Clients from './pages/dashboard-pages/admin/clients';
import RequestDetails from './pages/dashboard-pages/admin/request-details';
import ContactRequestsPage from './pages/dashboard-pages/admin/contact-request';
import JobOffers from './pages/dashboard-pages/admin/job-offers';

const supabase = createClient('https://tjqxbasscobnoichlzlo.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRqcXhiYXNzY29ibm9pY2hsemxvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjU2ODM1NjEsImV4cCI6MjA0MTI1OTU2MX0.uNHjNUxbNWUxwMfl-7gUld1olYcaxLLr-nVLeRRcsTA');


function App() {
  const [dashboardChildren, setDashboardChildren] = useState<Array<RouteObject>>();
  const [client, setClient] = useState<Client>();
  axios.defaults.baseURL = 'https://backend.laboria.work/api/';
  //axios.defaults.baseURL = "https://laboria.azurewebsites.net/api/"
  axios.defaults.headers.common['Accept'] = '*/*'
  axios.defaults.headers.common['Apikey'] = 'TGFib3JpYVdlYkFwaUtleVNlY3JldFByb2plY3RITjIwMjQ=';


  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session: any) => {
      if (session) {
        const jwt = jwtDecode(session.access_token) as any;
        if (!client) {
          getClient({ userId: jwt.sub, email: jwt.email });
        }
      } else {
        setDashboardChildren(undefined);
        setClient(undefined);
      }
    });

    return () => subscription.unsubscribe()
  }, []);

  const getClient = (clientRequest: { userId: string, email: string }) => {
    clientService.getByUserId(clientRequest.userId, clientRequest.email)
      .then(resp => {
        console.log(resp.data);
        localStorage.setItem('userId', resp.data.id.toString());
        setClient(resp.data);
        if (resp.data.role === 'admin') {
          setDashboardChildren([
            {
              path: "",
              element: <RequestsPage />,
            },
            {
              path: "requests",
              element: <RequestsPage />
            },
            {
              path: "details/:id",
              element: <RequestDetails />
            },
            {
              path: "clients",
              element: <Clients />
            },
            {
              path: "jobOffers/:clientId/:name",
              element: <JobOffers />
            },
            {
              path: "contactRequests",
              element: <ContactRequestsPage />
            },
          ])
        }
        if (resp.data.role === 'client') {
          setDashboardChildren([
            {
              path: "",
              element: <ClientHome />,
              loader: () => {
                if (!resp.data.isCompleted) {
                  return redirect('setup');
                }
                return redirect('home');
              }
            },
            {
              path: "home",
              element: <ClientHome />
            },
            {
              path: "offers",
              element: <JobOffer />
            },
            {
              path: "setup",
              loader: () => {
                if (resp.data.isCompleted) {
                  return redirect('home');
                }
                return null;
              },
              element: <SetupPage {...resp.data} />
            },
            {
              path: "*",
              element: <ClientHome />
            }
          ])
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />
    },
    {
      path: "openings",
      element: <OpeningPage />,
      children: [
        {
          path: "",
          element: <OpeningList />
        },
        {
          path: "request/:jobOfferId",
          element: <OpeningRequestStepper />
        },
      ]
    },
    {
      path: "dashboard",
      element: <DashboardPage client={client} />,
      children: dashboardChildren
    },
    {
      path: "contactUs",
      element: <ContactUsPage />,
    },
    {
      path: "confirmation",
      element: <Confirmation />,
    },
    {
      path: "*",
      element: <LandingPage />
    }
  ]);
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
