import { useTranslation } from "react-i18next";
import NavBar from "../../shared/components/nav-bar";
import LandingSection from "../../shared/components/landing-section";
import LandingHome from "./home";
import LandingAbout from "./about";
import theme from "../../theme";
import Proccess from "./proccess";
import Footer from "./footer";


export default function LandingPage() {
    const { t } = useTranslation();
    return (

        <NavBar showLinks={true}>
            <LandingSection
                id={t("nav.home")}
                height={800}
                style={{
                    width: '100%',
                    backgroundColor: theme.palette.background.paper
                }}>
                <LandingHome />
            </LandingSection>

            {/* <LandingSection
                id={t("nav.about")}
                height={1100}
                style={{
                    width: '100%'
                }}
            >
                <LandingAbout />
            </LandingSection>
            <LandingSection
                id={t("nav.steps")}
                height={1000}
                style={{
                    width: '100%',
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <Proccess />
            </LandingSection> */}

            <Footer/>
        </NavBar>
    );
}