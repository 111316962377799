import { Box, Button, CircularProgress, Container, MenuItem, TextField, Typography } from "@mui/material";
import NavBar from "../shared/components/nav-bar";
import { useEffect, useState } from "react";
import { City, Country, State } from "country-state-city";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import clientService from "../shared/services/client-service";
import { useNavigate } from "react-router-dom";

export interface ContactRequest {
    companyName: string;
    companyDescription: string;
    contactName: string;
    phoneNumber: string;
    country: string;
    state: string;
    city: string;
    address: string;
    email: string;
    message?: string;
}

export default function ContactUsPage() {
    const { register, handleSubmit, formState: { errors } } = useForm<ContactRequest>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');
    const countries = Country.getAllCountries();
    const [states, setStates] = useState<Array<any>>([]);
    const [cities, setCities] = useState<Array<any>>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setStates(State.getStatesOfCountry(selectedCountry));
    }, [selectedCountry]);

    useEffect(() => {
        setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }, [selectedState]);

    const handleForm: SubmitHandler<any> = (data: ContactRequest) => {
        setLoading(true);
        clientService.contactUs(data)
            .then(resp => {
                setLoading(false);
                navigate("/confirmation");
            }).catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <NavBar showLinks={false}>
            <Box
                sx={{ marginTop: 10 }}>
                <Container>
                    {loading
                        ? <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex', marginTop: 25 }}>
                            <CircularProgress size={100} />
                        </Box>
                        : <Box
                            borderRadius={2}
                            bgcolor={"Background"}
                            boxShadow={1}
                            sx={{ padding: 2, height: 'auto', marginBottom: 5 }}>
                            <Typography variant="h4">
                                Contact Us
                            </Typography>

                            <form style={{
                                marginTop: 5,
                                paddingTop: 5,
                                paddingBottom: 20
                            }} onSubmit={handleSubmit(handleForm)}>

                                <TextField
                                    label={t('forms.contact.companyName')}
                                    sx={{ minWidth: '40%', margin: 2 }}
                                    type="text"
                                    {...register('companyName',
                                        {
                                            required: true,
                                        }
                                    )}
                                    error={errors!.companyName ? true : false}
                                />

                                <TextField
                                    label={t('forms.contact.companyDescription')}
                                    sx={{ minWidth: '50%', margin: 2 }}
                                    type="text"
                                    {...register('companyDescription',
                                        {
                                            required: true
                                        }
                                    )}
                                    error={errors!.companyDescription ? true : false} />

                                <TextField
                                    label={t('forms.contact.contactName')}
                                    sx={{ minWidth: '40%', margin: 2 }}
                                    type="text"
                                    {...register('contactName',
                                        {
                                            required: true
                                        }
                                    )}
                                    error={errors!.contactName ? true : false}
                                />

                                <TextField
                                    label={t('forms.contact.email')}
                                    sx={{ minWidth: '50%', margin: 2 }}
                                    type="email"
                                    {...register('email',
                                        {
                                            required: true
                                        }
                                    )}
                                    error={errors!.email ? true : false}
                                />

                                <TextField
                                    id="country"
                                    label={t('forms.contact.country')}
                                    variant="outlined"
                                    select
                                    {...register('country',
                                        {
                                            required: true,
                                            onChange: (value) => { setSelectedCountry(value.target.value) }
                                        })}
                                    sx={{
                                        minWidth: 260,
                                        margin: 2
                                    }}
                                    error={errors!.country ? true : false}
                                >
                                    {countries.map(country => {
                                        return (<MenuItem key={country.isoCode} value={country.isoCode}>{country.name}</MenuItem>);
                                    })}
                                </TextField>

                                <TextField
                                    id="state"
                                    label={t('forms.contact.state')}
                                    variant="outlined"
                                    select
                                    {...register('state',
                                        {
                                            required: true,
                                            onChange: (value) => { setSelectedState(value.target.value) }
                                        })}
                                    sx={{
                                        minWidth: 260,
                                        margin: 2
                                    }}
                                    error={errors!.state ? true : false}
                                >
                                    {states.map(state => {
                                        return (<MenuItem key={state.isoCode} value={state.isoCode}>{state.name}</MenuItem>);
                                    })}
                                </TextField>

                                <TextField
                                    id="city"
                                    label={t('forms.contact.city')}
                                    variant="outlined"
                                    select
                                    {...register('city',
                                        {
                                            required: true,
                                        })}
                                    sx={{
                                        minWidth: 260,
                                        margin: 2
                                    }}
                                    error={errors!.city ? true : false}
                                >
                                    {cities.map(city => {
                                        return (<MenuItem key={city.isoCode} value={city.name}>{city.name}</MenuItem>);
                                    })}
                                </TextField>

                                <TextField
                                    multiline
                                    maxRows={2}
                                    id="address"
                                    label={t('forms.contact.address')}
                                    variant="outlined"
                                    {...register('address', { required: true })}
                                    sx={{
                                        margin: 2,
                                        minWidth: '90%'
                                    }}
                                    error={errors!.address ? true : false}
                                />

                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    style={{
                                        width: '30%',
                                        marginTop: 10,
                                        marginBottom: 5,
                                        marginLeft: 20,
                                    }}
                                    numberInputProps={{
                                        style: {
                                            height: 40,
                                            fontSize: 'medium'
                                        }
                                    }}
                                    {...register('phoneNumber', {
                                        required: true,
                                        minLength: { value: 6, message: "Phone number is required." }
                                    })}
                                    error={errors!.phoneNumber ? true : false}
                                    defaultCountry={selectedCountry as any}
                                    placeholder={t('forms.contact.phoneNumber')}
                                    onChange={() => console.log()} />
                                {errors!.phoneNumber?.message
                                    ? <Typography color={'error'}>{errors!.phoneNumber?.message.toString()}</Typography>
                                    : null}

                                <TextField
                                    multiline
                                    maxRows={4}
                                    rows={2}
                                    id="message"
                                    label={t('forms.contact.message')}
                                    variant="outlined"
                                    {...register('message')}
                                    sx={{
                                        margin: 2,
                                        minWidth: '90%'
                                    }} />

                                <Button
                                    sx={{ margin: 2 }}
                                    type="submit" title={t('forms.contact.send')} variant="contained">{t('forms.contact.send')} </Button>
                            </form>
                        </Box>
                    }

                </Container>
            </Box>
        </NavBar>)
}