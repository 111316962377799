import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useEffect, useState } from "react";
import OpeningDetailsModal from "./opening-details-modal";
import { Link } from "react-router-dom";
import jobApplicationService from "../../services/job-application-service";

export interface Opening {
    id: number;
    position: string;
    field: string;
    description: string;
    location: string;
};



export default function OpeningList() {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState<any>();

    useEffect(() => {
        setLoading(true);
        jobApplicationService.getJobOffers()
            .then(resp => {
                setData(resp.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
    }, []);

    const columns: GridColDef<(any)[number]>[] = [
        {
            field: 'position',
            headerName: t("openings.list.position"),
            width: 150,
        },
        {
            field: 'field',
            headerName: t("openings.list.field"),
            width: 150,
        },
        {
            field: 'location',
            headerName: t("openings.list.location"),
            width: 150,
        },
        {
            field: 'description',
            headerName: t("openings.list.description"),
            width: 300,
        },
        {
            field: 'options',
            headerName: t("openings.list.options"),
            width: 150,
            renderCell: (params: any) => (
                <Box>
                    <IconButton color="primary" onClick={() => {
                                    setSelectedOffer(params.row);
                                    setOpenDetails(true);
                                    }}>
                        <VisibilityIcon />
                    </IconButton>

                    <Link to={`request/${params.row.id}`} >
                        <IconButton color="primary">
                            <PostAddIcon />
                        </IconButton>
                    </Link>

                </Box>

            )
        },
    ];

    if (loading) {
        return (
            <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                <CircularProgress size={100} />
            </Box>
        );
    }

    return (
        <Box>
            <Typography
                component={"h1"}
                fontWeight={"bold"}
                fontSize={30}
            >{t("openings.title")}
            </Typography>

            <Grid sx={{
                display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block"
                }
            }}>
                <DataGrid
                    sx={{
                        marginTop: 5,
                        width: '100%',
                    }}
                    rows={data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                />
            </Grid>
            <Grid  sx={{
                display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none"
                }
            }}>
                <ul>
                    {
                        data.map((opening: any) => (
                            <li key={opening.id}>
                                <Button onClick={() => {
                                    setSelectedOffer(opening);
                                    setOpenDetails(true);
                                    }}>{opening.position}</Button>
                            </li>))
                    }
                </ul>
            </Grid>
            {
                openDetails ? <OpeningDetailsModal details={selectedOffer} open={openDetails} setOpen={setOpenDetails} /> : null
            }
        </Box>

    )
}