import { Box, Divider, Typography } from "@mui/material";
import theme from "../../theme";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer() {
    return (
        <Box height={150} sx={{
            backgroundColor: theme.palette.background.default,
            paddingTop: 5,
            color: theme.palette.primary.main,
            textAlign: 'center'
        }} alignContent={'center'}>
            <InstagramIcon sx={{ marginRight: 2}}/>
            <FacebookIcon sx={{ marginRight: 2}}/>
            <LinkedInIcon sx={{ marginRight: 2}}/>
            <Divider />
            <Typography variant="h6">&copy; 2024 Laboria.</Typography>
        </Box>
    )
}