import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a7d04', // Verde Oscuro
    },
    secondary: {
      main: '#6ec6ff', // Azul Claro
    },
    error: {
      main: '#d32f2f', // Rojo
    },
    warning: {
      main: '#ffa726', // Amarillo
    },
    info: {
      main: '#2196f3', // Azul
    },
    success: {
      main: '#4caf50', // Verde
    },
    background: {
      default: '#f4f4f4', // Gris muy claro
      paper: '#ffffff', // Blanco
    },
    text: {
      primary: '#333333', // Gris Oscuro
      secondary: '#000000', // Negro
    }
  },
});

export default theme;