import { Box, Container } from "@mui/material"
import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import theme from "../theme"
import logo from '../assets/logo.png';
import { useTranslation } from "react-i18next";


export default function LoginPage(props: { supabase: any }) {
    const { t } = useTranslation();
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box width={400} marginTop={15}>
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <a href="./">
                        <img src={logo} height={60} width={250} />
                    </a>
                </Box>

                <Auth
                    view="sign_in"
                    supabaseClient={props.supabase}
                    providers={[]}
                    redirectTo="http://localhost:3000/dashboard"
                    showLinks={false}
                    appearance={{
                        theme: ThemeSupa,
                        variables: {
                            default: {
                                colors: {
                                    brand: theme.palette.primary.main,
                                    brandAccent: theme.palette.success.main
                                }
                            }
                        }
                    }}
                    localization={{
                        variables: {
                            sign_in: {
                                email_label: t("auth.signIn.email_label"),
                                email_input_placeholder: t('auth.signIn.email_input_placeholder'),
                                password_label: t('auth.signIn.password_label'),
                                password_input_placeholder: t('auth.signIn.password_input_placeholder'),
                                button_label: t('auth.signIn.button_label'),
                                loading_button_label: t('auth.signIn.loading_button_label'),
                                link_text: t('auth.signIn.link_text')
                            },
                            sign_up: {
                                email_label: t('auth.signUp.email_label'),
                                email_input_placeholder: t('auth.signUp.email_input_placeholder'),
                                password_label: t('auth.signUp.password_label'),
                                password_input_placeholder: t('auth.signUp.password_input_placeholder'),
                                link_text: t('auth.signUp.link_text'),
                                button_label: t('auth.signUp.button_label'),
                                loading_button_label: t('auth.signUp.loading_button_label')
                            },
                            forgotten_password: {
                                email_label: t('auth.forgottenPassword.email_label'),
                                email_input_placeholder: t('auth.forgottenPassword.email_input_placeholder'),
                                password_label: t('auth.forgottenPassword.password_label'),
                                button_label: t('auth.forgottenPassword.button_label'),
                                loading_button_label: t('auth.forgottenPassword.loading_button_label'),
                                link_text: t('auth.forgottenPassword.link_text'),
                                confirmation_text: t('auth.forgottenPassword.confirmation_text')
                            }
                        }
                    }} />
            </Box>
        </Container>


    )
}