const translation = {
    title: 'Bienvenido a Laboria',
    language: 'Idioma',
    nav: {
        home: 'Inicio',
        about: 'Nosotros',
        contact: 'Contacto',
        openings: 'Vacantes',
        steps: 'Pasos'
    },
    landing: {
        applyNow: 'Aplica ahora',
        contactUs: 'Contactanos'
    },
    openings: {
        title: 'Vacantes disponibles',
        details: {
            description: "Descripción",
            location: "Ubicación",
            salary: "Salario",
            apply: "Aplicar"
        },
        list: {
            position: 'Posición',
            field: 'Campo',
            description: 'Descripción',
            location: 'Ubicación',
            options: 'Opciones'
        }
    },
    steps: {
        personalInfo: 'Información personal',
        dsForm: 'DS-160'
    },
    forms: {
        personalInfo: {
            idIsNotValid: 'Ya existe una aplicación con este número de identificación',
            id: 'Identificación',
            name: 'Nombre',
            middleName: 'Segundo nombre',
            lastName: 'Apellidos',
            gender: 'Género',
            maritalStatus: 'Estado civil',
            partnerName: 'Nombre del cónyuge',
            haveKids: '¿Tiene hijos?',
            educationLevel: 'Nivel educativo',
            profession: 'Profesión u ocupación',
            englishLevel: 'Nivel de inglés',
            workExperience: 'Experiencia laboral',
            havePassport: '¿Tiene pasaporte?',
            visa: '¿Ha recibido algún tipo de visa de los Estados Unidos?',
            country: 'País',
            state: 'Departamento',
            city: 'Ciudad',
            address: 'Dirección',
            phone: 'Número de teléfono',
            birthDate: 'Fecha de nacimiento',
            email: 'Correo electrónico',
            next: 'Siguiente'
        },
        genderOptions: {
            male: 'Masculino',
            female: 'Femenino',
            other: 'Prefiero no decirlo'
        },
        maritalStatusOptions: {
            single: 'Soltero/a',
            married: 'Casado/a',
            divorced: 'Divorciado/a',
            widowed: 'Viudo/a',
            separated: 'Separado/a'
        },
        educationalLevels: {
            noFormalEducation: 'Sin educación formal',
            primaryEducation: 'Educación primaria',
            secondaryEducation: 'Educación secundaria',
            vocationalQualification: 'Cualificación vocacional',
            bachelorDegree: 'Grado de licenciatura',
            masterDegree: 'Grado de maestría',
            doctorate: 'Doctorado o superior'
        },
        englishLevels: {
            basic: "Básico",
            intermediate: "Intermedio",
            advanced: "Avanzado",
            fluent: "Fluido"
        },
        visaTypes: {
            No: "No",
            B2: "Visa de visitante (B-2)",
            B1: "Visa de negocios (B-1)",
            H1B: "Visa de trabajo temporal (H-1B)",
            F1: "Visa de estudiante (F-1)",
            J1: "Visa de visitante de intercambio (J-1)",
            K1: "Visa de prometido/a (K-1)",
            CR1: "Visa de cónyuge (CR-1/IR-1)",
            E2: "Visa de inversionista (E-2)",
            O1: "Visa de artista/académico (O-1)",
            R1: "Visa de trabajador religioso (R-1)"
        },
        dsForm: {
            haveDirectRelativeInUS: "¿Tiene algún familiar directo en Estados Unidos, sin incluir a sus padres?",
            relatives: "Detalle quién.",
            deniedVisa: "¿Le han negado la visa alguna vez?",
            canceledVisa: "¿Le han cancelado la visa alguna vez?",
            citizenRequest: "¿Ha solicitado la ciudadanía en los Estados Unidos alguna vez?",
            outOfCountryLastFiveYears: "¿Ha salido del país en los últimos 5 años?",
            visitedCountries: "¿Qué países ha visitado?",
            haveBeenOut: "¿Ha residido en otro país por más de 6 meses después de haber cumplido 16 años?",
            triedEnterIllegallyToUs: "¿Ha intentado ingresar a los Estados Unidos de manera ilegal alguna vez?"
        },
        contact: {
            companyName: "Nombre de la empresa",
            companyDescription: "Descripción de la empresa",
            contactName: "Nombre de contacto",
            phoneNumber: "Número telefonico",
            country: "País",
            state: "Estado/Departamento",
            city: "Ciudad",
            address: "Dirección",
            email: "Correo",
            message: "Mensaje",
            send: "Envíar"
        }
    },
    auth: {
        signUp: {
            email_label: 'Tu correo electrónico',
            password_label: 'Crea tu contraseña',
            email_input_placeholder: 'Tu correo electrónico',
            password_input_placeholder: 'Tu contraseña',
            button_label: 'Registrarse',
            loading_button_label: 'Registrando ...',
            link_text: "¿No tienes una cuenta? Inicia sesión",
            confirmation_text: "Revisa tu correo para confirmar tu cuenta"
        },
        signIn: {
            email_label: "Correo electrónico",
            password_label: "Tu contraseña",
            email_input_placeholder: "Tu correo electrónico",
            password_input_placeholder: "Tu contraseña",
            button_label: "Inicia sesión",
            loading_button_label: "Iniciando sesión ...",
            social_provider_text: "Sign in with {{provider}}",
            link_text: "¿Ya tienes una cuenta? Inicia sesión"
        },
        forgottenPassword: {
            email_label: "Dirección de correo electrónico",
            password_label: "Tu contraseña",
            email_input_placeholder: "Tu dirección de correo electrónico",
            button_label: "Enviar instrucciones para restablecer la contraseña",
            loading_button_label: "Enviando instrucciones de restablecimiento ...",
            link_text: "¿Olvidaste tu contraseña?",
            confirmation_text: "Revisa tu correo electrónico para obtener el enlace de restablecimiento de contraseña"
        }
    },
    client: {
        setUpTitle: 'Antes de comenzar, solo necesitamos completar algunos datos.',
        email: 'Correo electrónico',
        organizationName: 'Nombre de organización',
        phoneNumber: 'Número de teléfono'
    }
};


export default {
    translation
}