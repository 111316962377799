import axios from "axios"
import Client from "../models/client";
import { ContactRequest } from "../../pages/contact-us";
import { GetContactRequestParams } from "../../pages/dashboard-pages/admin/contact-request";


function createClient(client: { userId: string, email: string }) {
    return axios.post("Client", {
        userId: client.userId,
        email: client.email
    });
}

function createClientFromContactRequest(requestId: number) {
    return axios.post(`Client/createFromContactRequest/${requestId}`);
}

function getByUserId(userid: string, email: string) {
    return axios.get<Client>(`Client/GetByUserId/${userid}/email/${email}`);
}

function updateClient(request: any) {
    return axios.put("Client", request);
}

function contactUs(request: ContactRequest) {
    return axios.post<boolean>('ContactRequest/contactUs', request);
}

function getAllContactRequests(params: GetContactRequestParams) {
    return axios.get<any>(`ContactRequest/getAll?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&status=${params.status}`);
}

function getAllContactRequestStatuses() {
    return axios.get<any>('ContactRequest/statuses');
}

function getAll(params: { searchText: string, pageSize: number, pageIndex: number }) {
    return axios.get<any>(`Client/all?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&name=${params.searchText}`);
}

function updateContactRequestStatus(id: number, newStatus: number) {
    return axios.put<boolean>(`ContactRequest/updateStatus/${id}?newStatus=${newStatus}`)
}

export default {
    createClient,
    createClientFromContactRequest,
    getByUserId,
    updateClient,
    contactUs,
    getAll,
    getAllContactRequests,
    getAllContactRequestStatuses,
    updateContactRequestStatus
}